.contact-us {
	background: #f5f5f5;
}
.contact-us h4 {
	padding-bottom: 10px;
}
.contact-us .map {
	width: 110%;
	transform: translateX(-10%);
}
form {
	background-color: #ffff;
	padding: 20px 40px;
	border-radius: 10px;
}
.contact-us .form-control {
	padding: 12px 5px;
	border: none;
	background: #f5f5f5;
	width: 100%;
}
.contact-us textarea {
	resize: none;
}
.contact-us label {
	color: grey;
	margin-bottom: 8px;
}
.contact-us label > span {
	color: red;
}
.contact-us .btnContainer {
	margin: 10px auto;
	text-align: center;
}
.contact-us .list-unstyled {
	margin-top: 20px;
}
.contact-us .list-unstyled li {
	padding-bottom: 15px;
	display: flex;
}

.contact-us .list-unstyled li img {
	margin-right: 15px;
}

.contact-us .btn,
.contact-us .btn:hover {
	background-color: #557fc0;
	width: 60%;
	border-radius: 6px;
	color: #fff;
}
.contact-us .form-control-box {
	margin: 10px auto;
}
.contact-us .alertBox {
	border: 1px solid red;
}
@media (max-width: 768px) {
	form {
		padding: 20px !important;
	}
	.contact-us .map {
		transform: translateX(0%);
		width: 100%;
	}
}
.btn:focus {
    outline: 0;
box-shadow: none !important;
}